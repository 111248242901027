@import '../../bento-styles.module';
@import '../../../../styles/dimensions';
@import '../../../../styles/breakpoints';

.boxOne {
    @extend %box;
    background-color: transparent;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    @include screen-max-width('lg') {
        background-position: center bottom;
        background-size: cover;
    }

    &.marketplace {
        background-position: right top;
    }
}

.videoContainer {
    @extend %box;
    left: 0;
    position: absolute;
    top: 0;
    z-index: 2;

    .backgroundVideo {
        @extend %box;
        height: 100%;
        object-fit: cover;
        position: absolute;
        width: 100%;
        z-index: 0;
    }
}

.boxOneContent {
    display: flex;
    flex: 1;
    left: 0;
    position: relative;
    top: 0;
    width: 100%;
    z-index: 1;

    @include screen-max-width('lg') {
        flex-direction: column;
        justify-content: flex-start;
        min-height: 500px;
        min-width: auto;
        width: 100%;
    }

    @include screen-max-width('md') {
        min-height: 400px;
    }

    @include screen-max-width('md') {
        min-height: 350px;
    }

    @include screen-max-width('sm') {
        min-height: 300px;
    }

    @include screen-max-width('xs') {
        min-height: 270px;
    }
}

.boxOneCopy {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    left: 0;
    min-width: 400px;
    padding: $gap-larger;
    position: relative;
    top: 0;
    z-index: 3;

    @include screen-max-width('lg') {
        justify-content: flex-start;
        min-height: 500px;
        min-width: auto;
        padding: $gap-x-large $gap-large;
    }

    @include screen-max-width('md') {
        justify-content: flex-start;
        min-height: 300px;
        min-width: auto;
        padding: $gap-small-medium $gap-medium;
    }
}

.heading div p {
    @extend %heading;

    @include screen-max-width('sm') {
        font-size: 40px !important;
    }
}

.headinggradient {
    @extend %headinggradient;
}

.subtitle div p {
    @extend %subtitle;
}

.media {
    display: flex;
    flex: 1;
    justify-content: center;

    .imageContainer {
        align-items: flex-start;
        border-radius: $gap-larger;
        display: flex;
        justify-content: center;
        overflow: hidden;
    }
}

.imageContainer div span {
    display: flex !important;
}

.image {
    height: auto !important;
    margin-bottom: 0 !important;
    min-height: auto !important;
    width: auto;
}

.copyImage {
    height: 102px;
    margin-bottom: $gap-medium;
    width: 170px;
}

.marketplaceSpacer {
    height: 120px;
    width: 100%;

    @include screen-max-width('md') {
        height: 180px;
    }

    @include screen-max-width('sm') {
        height: 140px;
    }
}

.buttons {
    form {
        align-items: flex-start;
    }
}
